<template>
  <div :class="$style.seo">
    <h2>SEO</h2>

    <el-form-item
      v-if="this.hasSetDefaultFlag"
      label-width="12.5rem"
      label="Заполнить по шаблону"
      prop="seo.setDefault"
    >
      <el-checkbox
        :value="setDefault"
        @input="$emit('update:setDefault', $event)"
        data-test-id="seoSetDefaultCheckbox"
      >
      </el-checkbox>
    </el-form-item>

    <el-form-item
      label-width="12.5rem"
      label="Заголовок страницы"
      :prop="(!this.setDefault && 'seo.title') || null"
    >
      <el-input
        :value="title"
        @input="$emit('update:title', $event)"
        data-test-id="seoTitleInput"
        :disabled="this.setDefault"
      ></el-input>
    </el-form-item>
    <el-form-item
      label-width="12.5rem"
      label="Описание"
      :prop="(!this.setDefault && 'seo.description') || null"
    >
      <el-input
        :value="description"
        @input="$emit('update:description', $event)"
        data-test-id="seoDescriptionInput"
        :disabled="this.setDefault"
      >
      </el-input>
    </el-form-item>

    <el-form-item
      label-width="12.5rem"
      label="Заголовок в соц. сетях"
      :prop="(!this.setDefault && 'seo.ogTitle') || null"
    >
      <el-input
        :value="ogTitle"
        @input="$emit('update:ogTitle', $event)"
        data-test-id="seoOgTitleInput"
        :disabled="this.setDefault"
      ></el-input>
    </el-form-item>

    <el-form-item
      label-width="12.5rem"
      label="Описание в соц. сетях"
      :prop="(!this.setDefault && 'seo.ogDescription') || null"
    >
      <el-input
        :value="ogDescription"
        @input="$emit('update:ogDescription', $event)"
        data-test-id="seoOgDescriptionInput"
        :disabled="this.setDefault"
      >
      </el-input>
    </el-form-item>
    <template v-if="isWithKeywords">
      <el-form-item
        label-width="12.5rem"
        label="Ключевые слова"
        :prop="(!this.setDefault && 'seo.keywords') || null"
      >
        <el-input
          :value="keywords"
          @input="$emit('update:keywords', $event)"
          data-test-id="seoKeywordsInput"
          :disabled="this.setDefault"
        >
        </el-input>
      </el-form-item>
    </template>
    <el-form-item
      label-width="12.5rem"
      label="Превью для соц. сетей"
      :prop="
        !this.setDefault
          ? isAddEvent
            ? 'seo.ogImage.origin'
            : 'seo.ogImage'
          : null
      "
    >
      <Uploader
        without-caption
        :limit="1"
        :files="setFiles(ogImage)"
        @upload="upload"
        data-test-id="seoOgImageUploader"
        :disabled="this.setDefault"
      />
    </el-form-item>
  </div>
</template>

<script>
import Uploader from '@/components/moleculs/AddwineUploader.vue'

export default {
  components: {
    Uploader,
  },
  props: {
    hasSetDefaultFlag: {
      type: Boolean,
      default: false,
    },
    setDefault: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isWithKeywords: {
      type: Boolean,
      default: false,
    },
    isAddEvent: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: String,
      default: '',
    },
    ogTitle: {
      type: String,
      default: '',
    },
    ogDescription: {
      type: String,
      default: '',
    },
    ogImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
    }
  },
  methods: {
    setFiles(ogImage) {
      return ogImage ? [{ original: ogImage }] : []
    },

    async upload(images) {
      const ogImage = images.length ? images[0].original : ''
      this.$emit('update:ogImage', ogImage)
    },
  },
}
</script>

<style lang="scss" module>
.seo {
  & > div > label {
    text-align: left;
  }
  h2 {
    margin-left: 2rem;
  }
}
</style>
