var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Editor',{attrs:{"value":_vm.value,"tag-name":"div","api-key":_vm.$configData.tiny_mce_key,"init":{
    height: _vm.height,
    menubar: false,
    plugins: [
      'advlist autolink lists link image media charmap print preview anchor code table help'
    ],
    menubar: 'file edit view format insert table help',
    automatic_uploads: false,
    file_picker_types: 'image',
    images_upload_url: 'postAcceptor.php',
    toolbar: _vm.toolbar
  }},on:{"input":function($event){return _vm.$emit('update:value', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }