import { ADDWINE_ROUTES } from '../routing'

export const SEO_TABS_TYPES = [
  { label: 'Все', value: 'all' },
  { label: 'Используемые', value: 'used' },
  { label: 'Не используемые', value: 'notUsed' },
]

export const EXTERNAL_PAGES_TYPES = {
  PRS_CATEGORY: 'PRS_CATEGORY',
  PRS_ASSORTMENT: 'PRS_ASSORTMENT',
}

export const EXTERNAL_PAGES_ROUTES = {
  [EXTERNAL_PAGES_TYPES.PRS_CATEGORY]: ADDWINE_ROUTES.CATALOG.CATEGORY,
  [EXTERNAL_PAGES_TYPES.PRS_ASSORTMENT]: ADDWINE_ROUTES.CATALOG.RANGES,
}

export const SEO_TYPES = {
  DEFAULT: 'DEFAULT',
  TEMPLATE: 'TEMPLATE',
}

export const SEO_TYPES_TO_DISPLAY = {
  [SEO_TYPES.DEFAULT]: 'Стандартные',
  [SEO_TYPES.TEMPLATE]: 'Шаблоны',
}

export const SEO_ENTITIES = {
  ARTICLE_CATEGORY: 'ARTICLE_CATEGORY',
  ASSORTMENT: 'ASSORTMENT',
  BLOG_ARTICLE: 'BLOG_ARTICLE',
  BRAND: 'BRAND',
  CATEGORY: 'CATEGORY',
  PRODUCT: 'PRODUCT',
  SOLUTION: 'SOLUTION',
}

export const SEO_ENTITIES_TO_DISPLAY = {
  [SEO_ENTITIES.ARTICLE_CATEGORY]: 'Категории статей',
  [SEO_ENTITIES.ASSORTMENT]: 'Ассортименты',
  [SEO_ENTITIES.BLOG_ARTICLE]: 'Статьи',
  [SEO_ENTITIES.BRAND]: 'Бренды',
  [SEO_ENTITIES.CATEGORY]: 'Категории',
  [SEO_ENTITIES.PRODUCT]: 'Продукты',
  [SEO_ENTITIES.SOLUTION]: 'Готовые решения',
}
