var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('el-upload',{class:_vm.$style.uploader,attrs:{"multiple":_vm.multiple,"auto-upload":false,"show-file-list":false,"on-change":_vm.upload,"disabled":_vm.files.length >= _vm.limit,"action":""}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("Загрузить")])],1),(_vm.files?.length)?[_c('div',{class:_vm.$style.photos},_vm._l((_vm.files),function(file,index){return _c('div',{key:file?.id ?? file?.original,class:_vm.$style.photo,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openModal(index)}}},[_c('img',{attrs:{"src":_vm.$configData.s3_link + file?.original,"alt":file?.caption}}),_c('div',{class:_vm.$style.trash,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.remove(file)}}},[_c('adw-icon',{attrs:{"name":"trash"}})],1),_c('div',{class:_vm.$style.arrow,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.moveLeft(index)}}},[_c('adw-icon',{attrs:{"name":"arrow-left"}})],1),_c('div',{class:[_vm.$style.arrow, _vm.$style.right],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.moveRight(index)}}},[_c('adw-icon',{attrs:{"name":"arrow-left"}})],1)])}),0)]:_vm._e(),(_vm.selectedPhotoIndex >= 0 && _vm.files[_vm.selectedPhotoIndex])?_c('div',{class:_vm.$style.modal,on:{"click":_vm.handleOutsideClick}},[_c('div',{ref:"leftButton",class:[
        _vm.$style.button,
        {
          [_vm.$style.disabled]: _vm.selectedPhotoIndex === 0,
        },
      ],on:{"click":_vm.prevPhoto}},[_c('adw-icon',{class:_vm.$style.arrow,attrs:{"name":"arrowLeftBackButton"}})],1),_c('adw-icon',{class:_vm.$style.close,attrs:{"name":"close"},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}}),_c('div',{ref:"modal",class:_vm.$style.content},[_c('div',{class:_vm.$style.photo},[_c('img',{attrs:{"src":_vm.$configData.s3_link + _vm.files[_vm.selectedPhotoIndex]?.original,"alt":_vm.files[_vm.selectedPhotoIndex]?.caption},on:{"error":_vm.setDefaultImage}}),_c('el-input',{class:_vm.$style.caption,attrs:{"value":_vm.files[_vm.selectedPhotoIndex].caption},on:{"input":function($event){return _vm.updateCaption($event, _vm.selectedPhotoIndex)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v("Caption")]},proxy:true}],null,false,588178347)})],1)]),_c('div',{ref:"rightButton",class:[
        _vm.$style.button,
        {
          [_vm.$style.disabled]: _vm.selectedPhotoIndex === _vm.files?.length - 1,
        },
      ],on:{"click":_vm.nextPhoto}},[_c('adw-icon',{class:[_vm.$style.arrow, _vm.$style.right],attrs:{"name":"arrowLeftBackButton"}})],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }