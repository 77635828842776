const transliterate = (text, engToRus) => {
  const rus =
      'щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь'.split(
        / +/g
      ),
    eng =
      'shh sh ch cz yu ya yo zh a y ee a b v g d e z i j k l m n o p r s t u f x y'.split(
        / +/g
      )

  for (let x = 0; x < rus.length; x++) {
    text = text
      .toLowerCase()
      .split(engToRus ? eng[x] : rus[x])
      .join(engToRus ? rus[x] : eng[x])
  }
  text = text.replaceAll(/[.,`'"№~<>’()!@#$%^&*+=?:;/\\|[\]{}]/g, '').trim()
  return text.replaceAll(' ', '-')
}

export { transliterate }
