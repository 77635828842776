<template>
  <div :class="$style.container">
    <el-upload
      :multiple="multiple"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="upload"
      :disabled="files.length >= limit"
      action=""
      :class="$style.uploader"
    >
      <el-button size="small" type="primary">Загрузить</el-button>
    </el-upload>

    <template v-if="files?.length">
      <div :class="$style.photos">
        <div
          v-for="(file, index) in files"
          :key="file?.id ?? file?.original"
          :class="$style.photo"
          @click.stop.prevent="openModal(index)"
        >
          <img
            :src="$configData.s3_link + file?.original"
            :alt="file?.caption"
          />
          <div :class="$style.trash" @click.stop.prevent="remove(file)">
            <adw-icon name="trash" />
          </div>
          <div :class="$style.arrow" @click.stop.prevent="moveLeft(index)">
            <adw-icon name="arrow-left" />
          </div>
          <div
            :class="[$style.arrow, $style.right]"
            @click.stop.prevent="moveRight(index)"
          >
            <adw-icon name="arrow-left" />
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="selectedPhotoIndex >= 0 && files[selectedPhotoIndex]"
      :class="$style.modal"
      @click="handleOutsideClick"
    >
      <div
        :class="[
          $style.button,
          {
            [$style.disabled]: selectedPhotoIndex === 0,
          },
        ]"
        ref="leftButton"
        @click="prevPhoto"
      >
        <adw-icon name="arrowLeftBackButton" :class="$style.arrow" />
      </div>
      <adw-icon name="close" :class="$style.close" @click.native="closeModal" />
      <div :class="$style.content" ref="modal">
        <div :class="$style.photo">
          <img
            :src="$configData.s3_link + files[selectedPhotoIndex]?.original"
            :alt="files[selectedPhotoIndex]?.caption"
            @error="setDefaultImage"
          />
          <el-input
            :value="files[selectedPhotoIndex].caption"
            :class="$style.caption"
            @input="updateCaption($event, selectedPhotoIndex)"
          >
            <template #prepend>Caption</template>
          </el-input>
        </div>
      </div>
      <div
        :class="[
          $style.button,
          {
            [$style.disabled]: selectedPhotoIndex === files?.length - 1,
          },
        ]"
        ref="rightButton"
        @click="nextPhoto"
      >
        <adw-icon
          name="arrowLeftBackButton"
          :class="[$style.arrow, $style.right]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import images from '@/mixins/images'

export default {
  mixins: [notifications, images],
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    files: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    deleteCallbackAction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectedPhotoIndex: null,
    }
  },
  methods: {
    async upload(file) {
      const loading = this.$loading({
        lock: true,
      })

      if (this.files.length > this.limit) {
        this.showNotification(
          `Файл ${file.name} не может быть загружен из-за превышения лимита(${this.limit}) загрузки`,
          'error',
        )
        return
      }

      const { value, error } =
        await delivery.StorageServiceCore.FilesActions.upload(file.raw)
      loading.close()
      if (error) return this.showNotification('Ошибка загрузки файла', 'error')

      this.files.push({
        original: value,
        caption: file.raw.name,
        order_field: this.files.length + 1,
        isActive: true,
      })
      this.$emit('upload', this.files)
    },
    async remove(image) {
      const isConfirm = confirm('Вы точно хотите удалить файл?')
      if (isConfirm) {
        if (image.id && this.deleteCallbackAction) {
          const { error } = await this.deleteCallbackAction(image.id)

          if (error)
            return this.showNotification('Ошибка удаления файла', 'error')
        }

        const newFiles = this.files
          .filter((e) => e.original !== image.original)
          ?.map((e, i) => ({ ...e, order_field: i + 1 }))

        this.$emit('upload', newFiles)
      }
    },
    updateCaption(value, index) {
      this.files[index].caption = value
      this.$emit('update:files', this.files)
    },
    openModal(index) {
      const html = document.querySelector('html')
      html.style.overflowY = 'hidden'
      this.selectedPhotoIndex = index
    },
    closeModal() {
      const html = document.querySelector('html')
      html.style.overflowY = 'auto'
      this.selectedPhotoIndex = null
    },
    handleOutsideClick(event) {
      if (
        !this.$refs.modal.contains(event.target) &&
        !this.$refs.leftButton.contains(event.target) &&
        !this.$refs.rightButton.contains(event.target)
      ) {
        this.closeModal()
      }
    },
    prevPhoto() {
      if (this.selectedPhotoIndex > 0) {
        this.selectedPhotoIndex--
      }
    },
    nextPhoto() {
      if (this.selectedPhotoIndex < this.files.length - 1) {
        this.selectedPhotoIndex++
      }
    },
    updateWithNewOrder(files) {
      this.$emit(
        'upload',
        files?.map((e, i) => ({ ...e, order_field: i + 1 })),
      )
    },
    moveRight(index) {
      const copyOfArr = [...this.files]
      if (index < copyOfArr.length - 1) {
        // eslint-disable-next-line no-extra-semi
        ;[copyOfArr[index], copyOfArr[index + 1]] = [
          copyOfArr[index + 1],
          copyOfArr[index],
        ]

        return this.updateWithNewOrder(copyOfArr)
      }

      const lastElement = copyOfArr.pop()
      copyOfArr.unshift(lastElement)
      return this.updateWithNewOrder(copyOfArr)
    },
    moveLeft(index) {
      const copyOfArr = [...this.files]
      if (index !== 0) {
        // eslint-disable-next-line no-extra-semi
        ;[copyOfArr[index], copyOfArr[index - 1]] = [
          copyOfArr[index - 1],
          copyOfArr[index],
        ]

        return this.updateWithNewOrder(copyOfArr)
      }

      const firstElement = copyOfArr.shift()
      copyOfArr.push(firstElement)
      this.updateWithNewOrder(copyOfArr)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  @include disableSelection;

  .uploader {
    margin-bottom: 2rem;
  }
  .photos {
    display: flex;
    flex-flow: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    .photo {
      position: relative;
      width: 14rem;
      height: 14rem;
      cursor: pointer;

      .trash {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        padding: 0.2rem;
      }
      .arrow {
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
      }
      .arrow {
        right: 0;
        left: 0.2rem;
        top: 0.4rem;
        &.right {
          left: 1.7rem;
          right: auto;
          transform: rotateY(0.5turn);
        }
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .modal {
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: $z-index-modal;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      border-radius: 0.5rem;
      padding: 1.5rem;
      max-width: 40rem;
      max-height: 40rem;
      .photo {
        position: relative;
        display: grid;
        place-items: center;
        gap: 2rem;
        img {
          max-height: 30rem;
          width: 100%;
          height: auto;
        }
        .caption {
          max-width: 20rem;
        }
      }
    }

    .button {
      display: grid;
      place-items: center;
      width: 5rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      @include bgRGBABlackOpacity(0.1);
      @include baseShadow;

      &:hover {
        @include bgRGBABlackOpacity(0.2);
      }

      &.disabled,
      &.disabled:hover {
        background-color: $light-gray;
        opacity: 0.3;
      }

      .arrow {
        width: 1.5rem;
        height: 1.5rem;
        color: $white;

        &.right {
          transform: rotate(180deg);
        }
      }
    }
    .close {
      position: absolute;
      top: 1rem;
      right: 6rem;
      cursor: pointer;
      color: $white;
      width: 2rem;
      height: 2rem;
      &:hover {
        color: $light-gray;
      }
    }
  }
}
</style>
