<template>
  <Editor
    @input="$emit('update:value', $event)"
    :value="value"
    tag-name="div"
    :api-key="$configData.tiny_mce_key"
    :init="{
      height: height,
      menubar: false,
      plugins: [
        'advlist autolink lists link image media charmap print preview anchor code table help'
      ],
      menubar: 'file edit view format insert table help',
      automatic_uploads: false,
      file_picker_types: 'image',
      images_upload_url: 'postAcceptor.php',
      toolbar: toolbar
    }"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    isMedia: {
      type: Boolean,
      default: true
    },
    isImage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      toolbar: `undo redo | styleselect | bold italic underline strikethrough link | \
            | numlist bullist outdent indent | | superscript subscript blockquote | alignleft aligncenter alignright alignjustify | \
          | removeformat | code | ${this.isImage ? 'image' : ''} | ${
        this.isMedia ? 'media' : ''
      } | help`
    }
  }
}
</script>
